import React, { ReactNode, useMemo, useState } from "react";
import { ActionItemList } from "src/common/client-contact/action-item-list";
import { ArchiveContactModal } from "./archive-contact-modal";

const bulkActions = [
  {
    id: "archive",
    label: "Archive",
    icon: "crud-archive",
    permissions: ["contacts_archive"],
    renderModal: ({
      search,
      filters,
      selection,
      closeModal,
    }: {
      search: string;
      filters: any;
      selection: any;
      closeModal: () => void;
    }) => {
      return <ArchiveContactModal search={search} filters={filters} selection={selection} onAfterClose={closeModal} />;
    },
  },
];

type BulkActionsProps = {
  allowedActions?: string[];
  filters: any;
  selection: any;
  search: string;
};

export function BulkActions({ allowedActions, filters, search, selection }: BulkActionsProps) {
  const filteredActions = useMemo(() => {
    if (!allowedActions) return bulkActions;
    return bulkActions.filter((actionItem) => {
      return allowedActions?.includes(actionItem.id);
    });
  }, [allowedActions]);
  const [renderModal, setRenderModal] = useState<() => ReactNode>();
  return (
    <>
      <ActionItemList
        actionItems={filteredActions}
        onActionClick={(actionItem) => {
          setRenderModal(
            () => () =>
              actionItem.renderModal({ search, filters, selection, closeModal: () => setRenderModal(undefined) })
          );
        }}
      />
      {renderModal?.()}
    </>
  );
}
