import { pluck } from "rxjs/operators";
import { fetchAsObservable, fetchWithSharedCache } from "fetcher!sofe";

export function getSources(forceBust = false) {
  return fetchWithSharedCache(`api/client_sources`, "#/client", forceBust).pipe(pluck("client_sources"));
}

export function getClientAndAll(clientId) {
  if (!clientId || clientId === "") {
    throw new Error("cannot get client without a clientID");
  }
  const include = "users,clients,tags,client_for,client_sources,integrations,roles,general_assigned";

  return fetchAsObservable(`api/clients/${clientId}?include=${include}`).pipe(pluck("clients"));
}

export function getUsers(role) {
  return fetchAsObservable(`wg/users${role ? "?role=" + role : ""}&basic=true`).pipe(pluck("users"));
}

function untilClientIdHasChanged(clientId) {
  const regex = new RegExp(`clients?/${clientId}`);
  return !regex.test(window.location.hash);
}
