import { useQuery } from "src/react-query";
import { TClient } from "../types";
import { clientQueries } from "src/queries";

export default function useClientQuery(
  {
    clientId,
    placeholderClient,
    includes,
  }: { clientId?: string; placeholderClient?: Omit<TClient, "id">; includes?: string },
  queryOptions?: object
) {
  const query = useQuery({
    ...clientQueries.client({ clientId, includes }),
    placeholderData: placeholderClient,
    ...queryOptions,
  });

  return {
    clientQuery: query,
    client: query.data,
  };
}
