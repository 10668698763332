import { concat, cloneDeep } from "lodash";
import { catchError, map, pluck } from "rxjs/operators";

import { fetchAsObservable, forceBustCache, fetchWithSharedCache } from "fetcher!sofe";

import { checkForFailedFetch } from "./resource.helpers.js";
import { partial } from "lodash";
import { TClient } from "src/common/types.js";

const pagesCache: any[] = [];

export function getClientNames(
  search: string,
  archivedOnly?: boolean,
  includeInactive?: boolean,
  includeClientGroups?: boolean
) {
  return fetchAsObservable(
    `/api/users/0/client-names?limit=20&name=${search}${archivedOnly ? "&archived_only=true" : ""}${
      includeInactive ? "&exclude_inactive_clients=false" : ""
    }${includeClientGroups ? "&client_groups=true" : ""}`
  ).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

export type GetClientNamesParams = {
  search: string;
  archivedOnly?: boolean;
  includeInactive?: boolean;
  includeClientGroups?: boolean;
};
export type ClientNamesResponse = Array<{
  client_groups: string[] | null;
  contacts: Array<{
    id: string;
    first_name: string;
    last_name: string;
  }>;
  display_name: string;
  name: string;
  id: string;
  is_business: boolean;
  primary_contact: {
    id: string;
    first_name: string;
    last_name: string;
  };
}>;

export function getClientNamesPromise(params: GetClientNamesParams) {
  return new Promise<ClientNamesResponse>((resolve, reject) => {
    const { search, archivedOnly, includeInactive, includeClientGroups } = params;
    getClientNames(search, archivedOnly, includeInactive, includeClientGroups).subscribe(resolve, reject);
  });
}

export function getClients(page: number, jql = defaultJql, limit = 100, sort = "name", asc = true) {
  if (!page) throw new Error("Cannot get clients without being given a page");

  return fetchAsObservable(`
    /api/clients?jql=${encodeURIComponent(JSON.stringify(jql))}&page=${page}&limit=${limit}&sort=${
    !asc ? "-" : ""
  }${sort}
  `).pipe(
    map((resp: any) => {
      const clients = resp.clients;
      pagesCache[page - 1] = clients;
      return {
        ...resp,
        clients: concat(...pagesCache.slice(0, page)),
      };
    }),
    catchError(checkForFailedFetch)
  );
}

export function searchClients(name: string, limit = 5) {
  return fetchAsObservable(`
    /api/clients?jql=${encodeURIComponent(JSON.stringify(buildQuery(name.toLowerCase())))}&limit=${limit}&sort=name
  `).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

/**
 * @param includes - A comma-separated list of includes. Available includes: users, clients, tags, client_for, client_sources
 */
export function getClient(id: string, includes?: string) {
  return fetchAsObservable(`api/clients/${id}${includes ? `?include=${includes}` : ""}`).pipe(
    pluck("clients"),
    catchError(checkForFailedFetch)
  );
}

export function getClientPromise(id: string, includes?: string) {
  return new Promise<TClient>((resolve, reject) => getClient(id, includes).subscribe(resolve, reject));
}

// Includes: a comma-separated list of includes. Available includes: users, clients, tags, client_for, client_sources
export function getClientCached(id: string, includes: string, bustCache = false) {
  return fetchWithSharedCache(
    `api/clients/${id}${includes ? `?include=${includes}` : ""}`,
    partial(untilClientIdHasChanged, id),
    bustCache
  ).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

function untilClientIdHasChanged(clientId: string) {
  const regex = new RegExp(`clients?/${clientId}`);
  return !regex.test(window.location.hash);
}

export function unarchiveClient(id: string) {
  return fetchAsObservable(`/api/clients/${id}/unarchive`, {
    method: "POST",
  });
}

export function deleteClient(id: string) {
  return fetchAsObservable(`/api/clients/${id}`, {
    method: "DELETE",
  }).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

export function patchClient(id: string, data: any) {
  return fetchAsObservable(`/api/clients/${id}?include=users,clients,tags,client_for,client_sources`, {
    method: "PATCH",
    body: { clients: data },
  }).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

export function putClient(id: string, data: any) {
  return fetchAsObservable(`/api/clients/${id}?include=users,clients,tags,client_for,client_sources`, {
    method: "PUT",
    body: { clients: data },
  }).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

export function postClient(data: any) {
  return fetchAsObservable(`/api/clients`, {
    method: "POST",
    body: { clients: data },
  }).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

export function clientsBulkAction(action: string, ids: string[]) {
  return fetchAsObservable(`/api/clients?action=${action}`, {
    method: "PATCH",
    body: { client_ids: ids },
  }).pipe(pluck("clients"), catchError(checkForFailedFetch));
}

export function getClientFromCache(clientId: string, includes: string) {
  if (!clientId) {
    throw new Error(`Must provide clientId to getClientFromCache`);
  }
  const query = includes ? `?include=${includes}` : "";
  return fetchAsObservable(`/api/clients/${clientId}${query}`, untilClientIdChanges).pipe(
    pluck("clients"),
    map(cloneDeep)
  );

  function untilClientIdChanges() {
    const regex = new RegExp(`clients?/${clientId}`);
    return !regex.test(window.location.hash);
  }
}

export function bustClientCache(clientId: string) {
  return forceBustCache(`/api/clients/${clientId}`);
}

export function buildCsvUrl() {
  return `/api/clients/csv?jql=${encodeURIComponent(JSON.stringify(defaultJql))}`;
}

export const defaultJql = [
  {
    field: "is_archived",
    operator: "eq",
    value: false,
  },
  {
    field: "is_hidden",
    operator: "eq",
    value: false,
  },
];

function buildQuery(term: any) {
  return [
    ...defaultJql,
    {
      OR: [
        {
          field: "person_name",
          operator: "beginswith",
          value: term,
        },
        {
          field: "business_name",
          operator: "beginswith",
          value: term,
        },
        {
          field: "last_name",
          operator: "beginswith",
          value: term,
        },
      ],
    },
  ];
}

export type ClientUsageResponse = {
  reached_limit: boolean;
  allowed: number;
  used: number;
};
export function getClientsUsage() {
  return fetchAsObservable(`api/clients/reached_limit`);
}
export function getClientsUsagePromise() {
  return new Promise<ClientUsageResponse>((resolve, reject) => getClientsUsage().subscribe(resolve, reject));
}

export function putRoleAssignments(clientId: string, body: any) {
  return fetchAsObservable(`api/clients/${clientId}/assignments`, {
    method: "PUT",
    body,
  });
}

export function putTags(clientId: string, tagNames: string[]) {
  return fetchAsObservable(`api/clients/${clientId}:tags`, {
    method: "PUT",
    body: { tags: tagNames.map((name) => ({ name })) },
  });
}

export function isDisplayNameAvailable(displayName: string) {
  return fetchAsObservable(`api/clients/display_name_available/${encodeURIComponent(displayName)}`);
}
