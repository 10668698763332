import * as React from "react";
import { CprButtonIcon } from "canopy-styleguide!sofe";

export default function Star(props) {
  return (
    <CprButtonIcon
      tooltip={props.html}
      icon={props.isPrimary ? "favorites" : "favorites-hollow"}
      onClick={props.setPrimary}
      customClass={props.isPrimary ? "cps-color-yellow" : "cps-color-monsoon"}
    />
  );
}
