import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { CpButton, CpModal } from "canopy-styleguide!sofe";
import { ContactInfoSection } from "./sections/contact-info-section/contact-info-section.component";
import { AssignedClientsSection } from "./sections/assigned-clients-section/assigned-clients-section.component";
import { createContactObs, updateContactObs } from "src/resources/contacts.resource";
import { contactToFormData, formDataToContact } from "../contact-modal.helper";
import { TContact } from "src/common/types";
import { TContactModalMode } from "../contact-modal-content.component";
import { handleError } from "src/error";
import { featureEnabled } from "feature-toggles!sofe";
import { clientQueries, contactQueries } from "src/queries";
import { ClientPortalSection } from "../view-contact/sections/client-portal/client-portal-section.component";

export type CreateEditContactProps = {
  contact?: TContact | Omit<TContact, "id">;
  contactId?: string;
  close: () => void;
  mode: TContactModalMode;
  setMode: (mode: TContactModalMode) => void;
  focusField?: string;
  onContactSave?: (contactResponse: any) => void;
};

export function CreateEditContact({
  contact,
  contactId,
  close,
  mode,
  setMode,
  focusField,
  onContactSave,
}: CreateEditContactProps) {
  const ft_view_contact_modal = featureEnabled("ft_view_contact_modal");
  const [saving, setSaving] = useState(false);
  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      emails: [{ isPrimary: true }],
      phones: [{ isPrimary: true }],
      addresses: [{ isPrimary: true, country: "United States" }],
    },
  });
  const {
    handleSubmit,
    formState: { isValidating, isSubmitting },
    setFocus,
    reset,
  } = formMethods;

  useEffect(() => {
    if (contact) {
      const formData = contactToFormData(contact);
      reset(formData, { keepDefaultValues: true });
    }
  }, [reset, contact]);

  useEffect(() => {
    if (focusField) {
      setFocus(focusField as any);
    }
  }, [focusField, setFocus]);

  async function onSubmit(data: any) {
    setSaving(true);
    const body = formDataToContact(data);
    const obs = mode === "edit" ? updateContactObs(contactId, body) : createContactObs(body);
    return new Promise<void>((resolve, reject) => {
      obs.subscribe(
        (res: any) => {
          onContactSave?.(res);
          contactQueries.invalidate();
          clientQueries.invalidate();
          ft_view_contact_modal ? setMode("view") : close();
          resolve();
        },
        (err: unknown) => {
          setSaving(false);
          reject(err);
          handleError(err);
        }
      );
    });
  }

  return (
    <>
      <CpModal.Header title={mode === "edit" ? "Edit Contact" : "Add Contact"} />
      <CpModal.Body>
        <FormProvider {...formMethods}>
          <div className="cp-flex-column cp-gap-24">
            <ContactInfoSection contact={contact} />
            {contact?.client_portal_email && "id" in contact && (
              <ClientPortalSection
                clientPortalInfo={{
                  clientPortalEmail: contact.client_portal_email,
                  hasClientPortalAccess: contact.clients?.some((client) => !!client.latest_invite_sent) ?? false,
                }}
                contactId={contact.id}
                contactName={contact.name ?? ""}
                wellLevel={1}
              />
            )}
            <AssignedClientsSection contactName={contact?.name} contactId={contactId} />
          </div>
        </FormProvider>
      </CpModal.Body>
      <CpModal.Footer>
        <CpButton
          btnType="primary"
          className="cp-mr-8"
          onClick={handleSubmit(onSubmit)}
          showLoader={isValidating || saving}
          disabled={isSubmitting}
        >
          Save
        </CpButton>
        <CpButton btnType="flat" onClick={!ft_view_contact_modal || mode === "create" ? close : () => setMode("view")}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </>
  );
}
