import React, { useState, useRef, useEffect, useMemo, lazy } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { CpOverlay, CpButton } from "canopy-styleguide!sofe";
import canopyUrls from "canopy-urls!sofe";
import { useHasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";
import { NavSection } from "./nav-section.component";
import type { NavSectionType } from "./types";
import { ClientTypeSection } from "./sections/client-type-section.component";
import { ClientInfoSection } from "./sections/client-info-section.component";
import { ContactsSection } from "./sections/contacts/contacts-section.component";
import { useNavScroll } from "./use-nav-scroll.hook";

import styles from "./create-edit-client-modal.styles.css";
import { CustomFieldsSection } from "./sections/custom-fields-section.component";
import { RolesSection } from "./sections/roles-sections.component";
import { TagsSection } from "./sections/tags-section.component";
import useClientQuery from "src/common/queries/use-client-query.hook";
import { clientToFormData, formToClientBody } from "./form.helper";
import { useTeamMembersQuery } from "src/common/queries/use-team-members-query.hook";
import { BusinessInfoSection } from "./sections/business-info-section.component";
import { TemplateButton } from "./template-button.component";
import { createClient, updateClient } from "src/resources/client-modal.resource";

import { TClient } from "src/common/types";
import useClientUsageQuery from "src/common/queries/use-client-usage-query.hooks";
import { ClientLimitModal } from "src/common/client-limit-modal.component";
import { IntegrationsSection } from "./integrations-options-section.component";
import { handleError } from "src/error";
import { clientQueries } from "src/queries";
import { useIntegrations } from "src/common/use-integrations.hook";
import { createRoot } from "react-dom/client";

export type CreateEditClientModalProps = {
  /** If provided then the modal will switch to edit mode. */
  clientId?: string;
  /** Initial client data to show in the edit modal. This will be replaced once all client data has been fetched. */
  placeholderClient?: Omit<TClient, "id">;
  /** Called on modal onAfterClose. The name onModalHide is for legacy reasons. */
  onModalHide?: () => void;
} & ScrollProps;

type ScrollProps =
  | {
      /** If provided the modal will scroll to that section on open. */
      initialActiveSectionId?: string;
      scrollToContact?: never;
    }
  | {
      /** If provided the modal will attempt to scroll to the contact id provided, or to the add/create contact buttons if 'new' is given. */
      scrollToContact?: string | "new";
      initialActiveSectionId?: never;
    };

const IntegrationEdit = lazy(() =>
  SystemJS.import("integrations-ui!sofe")
    .then((mod: any) => mod.getIntegrationEdit())
    .catch(handleError)
);

export default function CreateEditClientModal(props: CreateEditClientModalProps) {
  const isCreate = !props.clientId;
  const { clientUsageQuery, clientUsage } = useClientUsageQuery({ enabled: isCreate });
  if (clientUsageQuery.isFetching && isCreate) {
    return null;
  }
  if (clientUsage?.reached_limit) {
    return <ClientLimitModal clientLimit={clientUsage.allowed} onClose={props.onModalHide} />;
  } else {
    return <ClientModal {...props} />;
  }
}

function ClientModal({
  clientId,
  initialActiveSectionId,
  placeholderClient,
  onModalHide,
  scrollToContact,
}: CreateEditClientModalProps) {
  const [fetchingEnabled, setFetchingEnabled] = useState(true);
  const [loggedInUser] = useWithUserAndTenant();
  const hasRolesAccess = useHasAccess("company_settings_roles");
  const [show, setShow] = useState(true);
  const [originalQboContactId, setOriginalQboContactId] = useState<string | undefined>();
  const [thirdPartySyncMethod, setThirdPartySyncMethod] = useState<"create-new" | "sync-existing">("create-new");
  const [integrationMatchingClient, setIntegrationMatchingClient] = useState<TClient | undefined>();
  const newContactSectionRef = useRef<HTMLDivElement | null>(null);
  const isEdit = !!clientId;

  const { teamMembers, teams } = useTeamMembersQuery();
  const { client, clientQuery } = useClientQuery(
    {
      clientId,
      includes:
        "users,clients,tags,client_for,client_sources,integrations,roles,general_assigned,contacts,client_groups",
      placeholderClient,
    },
    {
      enabled: isEdit && fetchingEnabled,
    }
  );

  const {
    integration,
    overrideIntegrationSync,
    syncClientToIntegration,
    setSyncClientToIntegration,
    qboRefreshTick,
    qboRefresh,
  } = useIntegrations({ client, isEdit });

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      client_since: new Date(),
      is_business: false,
      is_active: true,
      client_type: "client",
      userTeams: isEdit ? [] : [loggedInUser],
      emails: [{ isPrimary: true }],
      phones: [{ isPrimary: true }],
      addresses: [
        {
          isPrimary: true,
          country: "United States",
        },
      ],
      use_external_name: false,
      third_party_contact: undefined,
    },
  });
  const {
    watch,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty, isSubmitting },
  } = formMethods;

  const isBusiness = watch("is_business");
  const thirdPartyContact = useWatch({ name: "third_party_contact", control });

  const sections: NavSectionType[] = useMemo(
    () => [
      ...(isEdit ? [] : [{ id: "client_type", name: "Client Type" }]),
      { id: "client_info", name: "Client Info" },
      ...(isBusiness ? [{ id: "business_info", name: "Business Info" }] : []),
      { id: "contacts", name: "Contacts" },
      { id: "custom_fields", name: "Custom Fields" },
      { id: "roles", name: hasRolesAccess ? "Roles" : "User/Team Assignments" },
      { id: "tags", name: "Tags" },
    ],
    [isEdit, isBusiness, hasRolesAccess]
  );

  useEffect(() => {
    if (client) {
      reset(clientToFormData({ client, teamMembers, teams }), { keepDirtyValues: true, keepDefaultValues: false });
    }
  }, [reset, client, teamMembers, teams]);

  const sectionRefs = useRef<Record<string, HTMLElement>>({});
  function applySectionRef(sectionId: string) {
    return (ref: HTMLElement) => (sectionRefs.current[sectionId] = ref);
  }

  const { activeSectionId, scrollToSection, onSectionScroll } = useNavScroll({
    sections,
    sectionRefs,
  });

  useEffect(() => {
    if (!clientQuery.isFetched && !clientQuery.isPlaceholderData) {
      // Only safe to scroll if we have placeholderData or if we have fetched the client data
      return;
    }
    if (initialActiveSectionId) {
      scrollToSection(initialActiveSectionId);
    } else if (scrollToContact) {
      setTimeout(() => {
        if (scrollToContact === "new" && newContactSectionRef.current) {
          newContactSectionRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          sectionRefs.current["contacts"]
            ?.querySelector(`[data-contact-id="${scrollToContact}"]`)
            ?.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  }, [initialActiveSectionId, scrollToSection, scrollToContact, clientQuery.isFetched, clientQuery.isPlaceholderData]);

  function onNavSectionClick(section: NavSectionType) {
    scrollToSection(section.id);
  }

  function close() {
    setFetchingEnabled(false);
    setShow(false);
  }

  function onSubmit(data: any, redirect: boolean) {
    const thirdPartyContactData = {
      overrideIntegrationSync: overrideIntegrationSync,
      syncClientToIntegration: syncClientToIntegration && thirdPartySyncMethod === "create-new",
      originalId: originalQboContactId,
    };
    const clientBody = formToClientBody(data, thirdPartyContactData);
    const obs = isEdit ? updateClient(loggedInUser, clientId, clientBody) : createClient(loggedInUser, clientBody);
    return new Promise<void>((resolve, reject) => {
      obs.subscribe(
        (res: any) => {
          if (
            !isEdit &&
            overrideIntegrationSync &&
            syncClientToIntegration &&
            thirdPartySyncMethod === "sync-existing"
          ) {
            // open matching modal
            setIntegrationMatchingClient({ ...res, redirect });
          } else {
            if (redirect) {
              canopyUrls.navigateToUrl(`#/client/${res.id}`);
            }
            close();
          }
          resolve();
        },
        (err: Error) => {
          reject(err);
          handleError(err);
        }
      );
    });
  }

  return (
    <>
      <CpOverlay
        show={show}
        onClose={close}
        onAfterClose={() => {
          onModalHide?.();
          clientQueries.invalidate();
        }}
        width={832}
      >
        <FormProvider {...formMethods}>
          <CpOverlay.Header title={`${isEdit ? "Edit" : "Create"} Client`}>
            <div className="flex cp-gap-16">
              {!isEdit && (
                <CpButton
                  btnType="secondary"
                  disabled={isSubmitting}
                  onClick={handleSubmit(async (data) => await onSubmit(data, true))}
                >
                  Save and manage
                </CpButton>
              )}
              <CpButton
                btnType="primary"
                disabled={isSubmitting}
                onClick={handleSubmit(async (data) => await onSubmit(data, false))}
              >
                Save client
              </CpButton>
            </div>
          </CpOverlay.Header>
          <CpOverlay.Body onScroll={onSectionScroll}>
            <div className="flex">
              <div className={styles.navSection}>
                <NavSection activeSectionId={activeSectionId} sections={sections} onSectionClick={onNavSectionClick} />
                <div className="cp-mt-24">
                  <IntegrationsSection
                    clientId={clientId}
                    integrationInfo={integration}
                    isBusiness={isBusiness}
                    isEdit={isEdit}
                    originalQboContactId={originalQboContactId}
                    setOriginalQboContactId={setOriginalQboContactId}
                    setSyncClientToIntegration={setSyncClientToIntegration}
                    setSyncMethod={setThirdPartySyncMethod}
                    syncClientToIntegration={syncClientToIntegration}
                    syncMethod={thirdPartySyncMethod}
                    thirdPartyUrl={client?.third_party_url}
                  />
                </div>
              </div>
              <div className="grow cp-pr-24 cp-pt-8 flex flex-col cp-gap-24">
                <div className="self-end">
                  <TemplateButton
                    isBusiness={isBusiness}
                    isFormDirty={isDirty}
                    isEdit={isEdit}
                    reset={reset}
                    getValues={getValues}
                  />
                </div>
                {!isEdit && <ClientTypeSection ref={applySectionRef("client_type")} control={control} />}
                <ClientInfoSection
                  ref={applySectionRef("client_info")}
                  isBusiness={isBusiness}
                  initialDisplayName={client?.name}
                />
                {isBusiness && (
                  <BusinessInfoSection ref={applySectionRef("business_info")} control={control} watch={watch} />
                )}
                <ContactsSection
                  ref={applySectionRef("contacts")}
                  newContactSectionRef={newContactSectionRef}
                  isBusiness={isBusiness}
                  thirdPartyContact={thirdPartyContact}
                />
                <CustomFieldsSection ref={applySectionRef("custom_fields")} control={control} isBusiness={isBusiness} />
                <RolesSection ref={applySectionRef("roles")} control={control} watch={watch} />
                <TagsSection ref={applySectionRef("tags")} control={control} />
              </div>
            </div>
          </CpOverlay.Body>
        </FormProvider>
      </CpOverlay>
      {overrideIntegrationSync && (
        <IntegrationEdit
          show={!!integrationMatchingClient}
          onClose={() => {
            if ((integrationMatchingClient as { redirect?: boolean })?.redirect) {
              canopyUrls.navigateToUrl(`#/client/${integrationMatchingClient?.id}`);
            }
            setIntegrationMatchingClient(undefined);
            close();
          }}
          integration={integration}
          client={integrationMatchingClient || {}}
          defaultSearchExisting
          qboRefreshTick={qboRefreshTick}
          qboRefresh={qboRefresh}
          contact={thirdPartyContact}
        />
      )}
    </>
  );
}

export function renderCreateEditClientModal(props: CreateEditClientModalProps) {
  const el = document.createElement("div");
  document.body.appendChild(el);
  const root = createRoot(el);
  root.render(
    <CreateEditClientModal
      {...props}
      onModalHide={() => {
        props.onModalHide?.();
        root.unmount();
        document.body.removeChild(el);
      }}
    />
  );
}
