import React, { useRef } from "react";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { CpInputField, CpButton, CpWell } from "canopy-styleguide!sofe";
import { Control, useForm, useFormContext } from "react-hook-form";
import { ContactInfoFields } from "src/common/form/contact/contact-info-fields.component";
import { ContactType } from "src/common/form/contact-type.component";
import { contactToFormData, formDataToContact } from "src/contact-modal/contact-modal.helper";
import { createContactObs, getContactObs, updateContactObs } from "src/resources/contacts.resource";
import { ContactSelectField } from "../../../common/form/contact/contact-select-field.component";
import { ContactWell } from "./contact-well.component";
import { handleError } from "src/error";
import { ScrollIntoView } from "src/common/scroll-into-view.component";
import { DependentRelationshipField } from "src/common/form/contact/dependent-relationship-field";
import { useModeValidation } from "./use-mode-validation";

export type TMode = "initial" | "add_existing" | "create" | "edit";

type InlineContactProps = {
  control: Control;
  fieldName: string;
  mode: TMode;
  onClose?: () => void;
  onModeChange?: (mode: TMode) => void;
  required?: boolean;
  isBusiness?: boolean;
};

export function InlineContact({
  control,
  fieldName,
  mode,
  onClose,
  onModeChange,
  required = false,
  isBusiness,
}: InlineContactProps) {
  useCssModules(css);
  const {
    handleSubmit,
    formState: { isSubmitting },
    control: contactFormControl,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      emails: [{ isPrimary: true }],
      phones: [{ isPrimary: true }],
      addresses: [{ isPrimary: true, country: "United States" }],
    },
  });

  const { setValue, watch: watchClientForm } = useFormContext();

  async function onContactSubmit(data: any) {
    const body = formDataToContact(data);
    const obs = mode === "create" ? createContactObs(body) : updateContactObs(data.id, body);
    return new Promise<void>((resolve, reject) => {
      obs.subscribe(
        (res: any) => {
          setValue(`${fieldName}.contact`, res.contact);
          onModeChange?.("add_existing");
          resolve();
        },
        (err: Error) => {
          reject(err);
          handleError(err);
        }
      );
    });
  }

  const formContact = watchClientForm(`${fieldName}.contact`);
  const contactType = watchClientForm(`${fieldName}.contact_type`);
  const contacts = watchClientForm("contacts");
  const hasPrimaryContact = contacts.some((c: any) => c.contact_type === "primary");
  function filterContacts(contactsToFilter: any) {
    const selectedContactIds = contacts?.map((c: any) => c?.contact?.id)?.filter((id: string) => id) || [];
    return contactsToFilter.filter((c: any) => !selectedContactIds.includes(c.id));
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const modeError = useModeValidation({ fieldName, control, mode, elToScrollToRef: containerRef });

  function switchToEdit() {
    getContactObs(formContact.id).subscribe((res: any) => {
      onModeChange?.("edit");
      reset(contactToFormData(res));
    }, handleError);
  }

  return (
    <div ref={containerRef} data-contact-id={formContact?.id}>
      {mode === "add_existing" && (
        <CpWell className="flex flex-col cp-gap-16 cp-p-24" level={2}>
          <div className="flex flex-col">
            <CpButton className="self-end" aria-label="Remove contact" icon="close-large" onClick={onClose} />
            <div className="flex flex-col cp-gap-16">
              <div className={s.grid}>
                <ContactSelectField
                  control={control}
                  fieldName={`${fieldName}.contact`}
                  label="Select contact"
                  required={required}
                  filterContacts={filterContacts}
                />
                <ContactType
                  control={control}
                  fieldName={`${fieldName}.contact_type`}
                  hasPrimaryContact={hasPrimaryContact}
                  isBusiness={isBusiness}
                  showLabelTooltip
                />
              </div>
              <div className={s.grid}>
                {contactType === "dependent" && (
                  <DependentRelationshipField fieldName={`${fieldName}.dependent_relationship`} control={control} />
                )}
                <CpInputField
                  label="Contact description"
                  placeholder="Contact description"
                  fieldName={`${fieldName}.contact_description`}
                  control={control}
                  maxLength={150}
                />
              </div>
              {!!formContact?.id && <ContactWell contact={formContact} onEdit={switchToEdit} />}
            </div>
          </div>
        </CpWell>
      )}
      {(mode === "create" || mode === "edit") && (
        <ScrollIntoView scrollBehavior="instant" className={s.createEditWellContainer}>
          <CpWell level={2} className={`${modeError ? s.errorBorder : ""} cp-p-16`}>
            <div className="flex justify-between cp-mb-24">
              <div className="cp-wt-semibold">{mode === "create" ? "Create new" : "Edit"} contact</div>
              <CpButton aria-label="Close contact form" icon="close-small" onClick={onClose} />
            </div>
            <ContactInfoFields control={contactFormControl} watch={watch} />
            <CpButton className="cp-mt-24" onClick={handleSubmit(onContactSubmit)} disabled={isSubmitting}>
              Save contact
            </CpButton>
          </CpWell>
          {modeError && <div className={s.errorText}>{modeError.message}</div>}
        </ScrollIntoView>
      )}
    </div>
  );
}

const { s, css } = cssModules`
  .grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    flex: 1;
    gap: 1.6rem;
  }

  .createEditWellContainer {
    scroll-margin: 1rem;
  }

  .errorBorder {
    border: 0.1rem solid var(--cp-color-input-error-border);
  }

  .errorText {
    color: var(--cp-color-app-error-text);
    margin-top: 0.4rem;
    font-size: 1.2rem;
  }
`;
