import React from "react";
// project
import Star from "../../star.component.js";
// sofe
import { CprButtonIcon } from "canopy-styleguide!sofe";

export default function Actions(props) {
  return (
    <>
      <Star isPrimary={props.isPrimary} setPrimary={props.setPrimary} html={props.starHtml} />
      <CprButtonIcon
        onClick={props.remove}
        icon={"trash"}
        tooltip={props.trashHtml}
        customClass={"cps-color-monsoon"}
      />
    </>
  );
}
