import { queryOptions } from "@tanstack/react-query";
import { genQueries } from "src/react-query";
import {
  ClientUsageResponse,
  GetClientNamesParams,
  getClientNamesPromise,
  getClientPromise,
  getClientsUsagePromise,
} from "src/resources/clients.resource";
import { TClient, TClientGroup } from "src/common/types";
import { getClientsPromise, GetClientsParams } from "src/client-list/client-list.resource";
import {
  GetClientGroupsParams,
  GetClientGroupsPromise,
  getClientGroupByIdPromise,
} from "src/resources/client-groups.resource";

export const clientQueries = genQueries("clients", ({ genKey }) => ({
  client: ({ clientId, includes }: { clientId?: string; includes?: string }) =>
    queryOptions<TClient | Omit<TClient, "id">>({
      queryKey: genKey("client", { clientId, includes }),
      queryFn: () => getClientPromise(clientId || "", includes),
      enabled: !!clientId,
    }),

  clients: (params: GetClientsParams) =>
    queryOptions<any>({
      queryKey: genKey("clients", params),
      queryFn: () => getClientsPromise(params),
    }),

  clientUsage: () =>
    queryOptions<ClientUsageResponse>({
      queryKey: genKey("clientUsage", {}),
      queryFn: () => getClientsUsagePromise(),
    }),

  clientNames: (params: GetClientNamesParams) =>
    queryOptions<any>({
      queryKey: genKey("clientNames", params),
      queryFn: () => getClientNamesPromise(params),
    }),

  clientGroup: (clientGroupId: string) =>
    queryOptions<TClientGroup>({
      queryKey: genKey("clientGroup", { clientGroupId }),
      queryFn: () => getClientGroupByIdPromise({ id: clientGroupId }),
    }),

  clientGroups: (params: GetClientGroupsParams) =>
    queryOptions<any>({
      queryKey: genKey("clientGroups", params),
      queryFn: () => GetClientGroupsPromise(params),
    }),
}));
