import React from "react";
import { CpLabel, CpSelectSingle } from "canopy-styleguide!sofe";
import { Control, Controller } from "react-hook-form";

interface DependentRelationshipFieldProps {
  control: Control;
  fieldName: string;
  disabled?: boolean;
}

export const dependentOptions = [
  "Son",
  "Daughter",
  "Step-son",
  "Step-daughter",
  "Nephew",
  "Niece",
  "Mother",
  "Father",
  "Step-mother",
  "Step-father",
  "Grandfather",
  "Grandmother",
  "Grandchild",
  "Aunt",
  "Uncle",
  "Cousin",
  "Friend",
  "Other",
];
const dependentData = dependentOptions.map((val) => ({ id: val, name: val }));

export function DependentRelationshipField({ control, fieldName, disabled }: DependentRelationshipFieldProps) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { value, onChange } }) => (
        <div>
          <CpLabel>Dependent relationship</CpLabel>
          <CpSelectSingle
            triggerIsBlock
            onChange={(value: any) => onChange(value?.id)}
            value={dependentData.find((opt) => opt.id === value)}
            placeholder="Select"
            data={dependentData}
            disabled={disabled}
          />
        </div>
      )}
    />
  );
}
