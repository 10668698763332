import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
  useMutation,
  queryClient,
  setupQueryKeygen,
} from "global-settings!sofe";
export { useQueryClient, useQuery, useInfiniteQuery, useMutation, queryClient };

export const { genBaseKey, genQueryKey, genQueries, invalidateEntity } = setupQueryKeygen("clients-ui");
