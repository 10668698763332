import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isEqual, omit } from "lodash";
import { CpTable } from "canopy-styleguide!sofe";
import type { FilterData, SortDataItem } from "src/common/client-contact/filters/types";
import {
  formFilterDataToQuery,
  filterViewToFormFilters,
  filterViewToFormSortData,
} from "src/common/client-contact/filters/filters.helper";
import useContactFilterViewsQuery from "./use-contact-filter-views-query.hook";
import { SubView } from "./use-contact-list-subview";

export function useContactListFilters({
  schema,
  routeId,
  subView,
  allSubViews,
}: {
  schema: any;
  routeId: string;
  subView: SubView | null;
  allSubViews: SubView[];
}) {
  const [activeFilterViewId, setActiveFilterViewId] = useState(routeId || "__ALL_CONTACTS");
  const [activeFilterView, setActiveFilterView] = useState<any>();
  const [filterViewOverrides, setFilterViewOverrides] = useState<any>({});
  const [filters, setFilters] = useState<FilterData>({});
  const [sortData, setSortData] = useState<SortDataItem[]>([]);
  const filterControl = CpTable.useFilters({
    schema: schema,
    onFilterApply: ({ sortDir, filterData, filterFieldId, sortFieldId, columnSchema }: any) => {
      setFilters((prev) => {
        if (filterData) {
          return {
            ...prev,
            [filterFieldId]: formFilterDataToQuery({
              filterData,
              filterFieldId,
              filterComponent: columnSchema.filter.component,
            }),
          };
        } else {
          const newFilters = { ...prev };
          delete newFilters[filterFieldId];
          return newFilters;
        }
      });
      setSortData((prev: SortDataItem[]) => {
        const newData = prev.filter((item: SortDataItem) => item.field !== sortFieldId);
        if (sortDir) {
          return [...newData, { field: sortFieldId, sort: sortDir }];
        } else {
          return newData;
        }
      });
    },
  });

  const { filterViews, filterViewsQuery } = useContactFilterViewsQuery();

  const getFilterViewById = useCallback(
    (viewId: string) => filterViews?.find((fv: any) => fv.id === viewId),
    [filterViews]
  );

  const { setFilters: fcSetFilters, setSortData: fcSetSortData } = filterControl;
  const setControlFilters = useCallback(
    (filterData: any) => {
      fcSetFilters(filterViewToFormFilters(filterData, schema));
      fcSetSortData(filterViewToFormSortData(filterData, schema));
    },
    [fcSetFilters, fcSetSortData, schema]
  );

  const newFiltersCount = useMemo(() => {
    if (!activeFilterView) return 0;

    const appliedFilters = Object.keys(filters).filter((name) => filters[name]?.length > 0);
    const filterFieldsToCheck = new Set([
      ...appliedFilters,
      ...Object.keys(activeFilterView.filter_data?.filters || {}),
      ...(activeFilterView?.sort_data?.map((d: any) => d.field) || []),
      ...sortData.map((d) => d.field),
    ]);
    const newFilters = new Set(); // A list of filter fields that differ from the filter view

    filterFieldsToCheck.forEach((name) => {
      const filterViewFilter = activeFilterView.filter_data?.filters?.[name] || [];
      const filter = filters[name] || [];
      if (!isEqual(filter, filterViewFilter)) {
        newFilters.add(name);
      }

      const filterViewSortData =
        activeFilterView.filter_data?.sort_data?.find((d: any) => d.field === name && !!d.sort) || {};
      const fieldSortData = sortData.find((d) => d.field === name && !!d.sort) || {};

      if (!isEqual(fieldSortData, filterViewSortData)) {
        newFilters.add(name);
      }
    });

    const newColumnOrder = !!filterViewOverrides[activeFilterView.id]?.columnOrder;
    const newVisibleColumns = !!filterViewOverrides[activeFilterView.id]?.visibleColumns;
    if (newColumnOrder) newFilters.add("columnOrder");
    if (newVisibleColumns) newFilters.add("visibleColumns");

    return newFilters.size;
  }, [filters, sortData, activeFilterView, filterViewOverrides]);

  const initFilters = useCallback(
    (filterView: any) => {
      setFilters(filterView.filter_data?.filters || {});
      setSortData(filterView.filter_data?.sort_data || []);
      setControlFilters(filterView.filter_data);
    },
    [setControlFilters]
  );

  function resetFilters() {
    initFilters(activeFilterView);
    setFilterViewOverrides((overrides: any) => omit(overrides, activeFilterViewId));
  }

  useEffect(() => {
    if (routeId) {
      setActiveFilterViewId(!routeId || routeId === "all" ? "__ALL_CONTACTS" : routeId);
    }
  }, [routeId]);

  const prevFilterViewId = useRef<string | undefined>();
  useEffect(() => {
    if (!filterViews?.length) return;
    const filterView = getFilterViewById(activeFilterViewId);
    if ((filterView || subView) && prevFilterViewId.current !== activeFilterViewId) {
      if (subView) {
        initFilters(subView);
        setActiveFilterView(subView);
      } else {
        initFilters(filterView);
        setActiveFilterView(filterView);
      }
      prevFilterViewId.current = activeFilterViewId;
    }
  }, [activeFilterViewId, getFilterViewById, initFilters, filterViews, subView]);

  useEffect(() => {
    if (!filterViewsQuery.isFetching) {
      const filterView = getFilterViewById(activeFilterViewId);
      const subViewIds = allSubViews.map((sv) => sv.id);
      if (!filterView && !subView) {
        if (!subViewIds.includes(activeFilterViewId)) {
          window.location.replace("#/contacts/list/all");
        }
      }
    }
  }, [filterViewsQuery.isFetching, activeFilterViewId, getFilterViewById, subView, allSubViews]);

  return {
    activeFilterView,
    cpTableFilterControl: filterControl,
    filterViewOverrides,
    filterViews,
    filters,
    getFilterViewById,
    newFiltersCount,
    resetFilters,
    setFilterViewOverrides,
    sortData,
    switchFilterView: (filterViewId: string) => {
      if (filterViewId === "__ALL_CONTACTS") {
        filterViewId = "all";
      }
      window.location.assign(`#/contacts/list/${filterViewId}`);
    },
  };
}
