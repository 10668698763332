import * as React from "react";
import { Scoped, a } from "kremling";
import { findIndex } from "lodash";
// project
import styles from "./list-of-items.krem.css";
import { InputDecorator } from "src/forms-lib";
// sofe
import { CprButton } from "canopy-styleguide!sofe";

class ListOfItems extends React.Component {
  static defaultProps = {
    value: [],
    updateValue: () => {},
    setFocus: () => {},
    setCustomGetValue: () => {},
    showInvalid: false,
    buttonMargin: true,
  };

  state = {
    deletedItems: [],
    mixedValuesOrder: this.props.value.map((item) => item.id),
  };

  itemRefs = [];

  componentDidMount() {
    this.props.setCustomGetValue(this.getValue);
    this.props.setFocus(this.focus);
  }

  render() {
    const renderFunctionProps = {
      removeRef: this.removeRef,
      addRef: this.addRef,
      removeItemById: this.removeItemById,
      setPrimaryById: this.setPrimaryById,
      updateItem: this.updateItem,
      deleteItem: this.deleteItem,
      cancelPendingDelete: this.cancelPendingDelete,
      setUniqueValueById: this.setUniqueValueById,
      showInvalid: this.props.showInvalid,
      currentValue: this.props.value,
    };
    const deletedItems = this.state.deletedItems.map((item) => item.id);
    const disabledButton = this.props.disabledAdd || this.props.maxCount === this.state.mixedValuesOrder.length;
    return (
      <Scoped css={styles}>
        <div>
          {this.state.mixedValuesOrder.map((itemId, index) => {
            const fnProps = {
              ...renderFunctionProps,
              itemsLength: this.state.mixedValuesOrder.length,
              item: undefined,
              index,
            };
            if (deletedItems.includes(itemId)) {
              fnProps.item = this.state.deletedItems[this.getIndexById(itemId, this.state.deletedItems)];
              return this.props.deleted(fnProps);
            } else {
              fnProps.item = this.props.value[this.getIndexById(itemId, this.props.value)];
              return this.props.item(fnProps);
            }
          })}
          <div className={a("addButtonZone").m("cps-margin-bottom-16", this.props.buttonMargin)}>
            {this.props.button ? (
              this.props.button({
                allItems: [...this.props.value, ...this.state.deletedItems],
                addItem: this.addItem,
                disabledButton,
              })
            ) : (
              <CprButton type="button" actionType="flat" onClick={this.addItem} disabled={disabledButton}>
                {this.props.buttonText}
              </CprButton>
            )}
          </div>
        </div>
      </Scoped>
    );
  }

  focus = () => {
    this.itemRefs.some((ref) => {
      if (ref.isInvalid && ref.isInvalid() === true) {
        ref.focus && ref.focus();
        return true;
      }
    });
  };

  addItem = () => {
    const newItem = this.props.getEmptyItem(this.props.value);
    const newItemId = newItem.id;
    const items = [...this.props.value];
    items.push(newItem);
    this.props.updateValue(items);
    this.setState(
      (prev) => {
        const mixedValuesOrder = [...prev.mixedValuesOrder];
        mixedValuesOrder.push(newItemId);
        return {
          mixedValuesOrder,
        };
      },
      () => {
        this.focusOnNewItem(newItemId);
        this.setFirstAsPrimaryIfNothingIsPrimary();
      }
    );
  };

  setFirstAsPrimaryIfNothingIsPrimary = () => {
    const deletedItems = this.state.deletedItems.map((item) => item.id);
    const filteredItems = this.props.value.filter((item) => !deletedItems.includes(item.id));
    const hasPrimaryItem = filteredItems.reduce((acc, item) => {
      return item.is_primary || acc;
    }, false);
    if (!hasPrimaryItem && filteredItems.length >= 1) {
      this.setPrimaryById(filteredItems[0].id, true);
    }
  };

  focusOnNewItem = (id) => {
    this.itemRefs.some((ref) => {
      if (ref && ref.props && ref.props.name && ref.props.name === id) {
        ref.focus && ref.focus();
        return true;
      }
    });
  };

  removeItemById = (id) => {
    this.setState((prev) => {
      const deletedItems = [...prev.deletedItems];
      const index = this.getIndexById(id, this.props.value);
      deletedItems.push({ ...this.props.value[index] });
      return { deletedItems };
    }, this.setFirstAsPrimaryIfNothingIsPrimary);
    const items = [...this.props.value];
    const filteredItems = items.filter((item) => item.id !== id);
    this.props.updateValue(filteredItems);
    this.setFirstAsPrimaryIfNothingIsPrimary();
  };

  deleteItem = (id) => {
    this.setState((prev) => {
      const deletedItems = [...prev.deletedItems].filter((item) => item.id !== id);
      const mixedValuesOrder = [...prev.mixedValuesOrder].filter((item) => item !== id);
      return { deletedItems, mixedValuesOrder };
    }, this.setFirstAsPrimaryIfNothingIsPrimary);
  };

  cancelPendingDelete = (id) => {
    const { value } = this.props;
    const index = this.getIndexById(id, this.state.deletedItems);
    const item = this.state.deletedItems[index];
    const formerIndex = this.state.mixedValuesOrder.indexOf(id);
    const items = [...value.slice(0, formerIndex), item, ...value.slice(formerIndex)];
    this.props.updateValue(items);
    this.setState(
      (prev) => {
        const deletedItems = [...this.state.deletedItems].filter((item) => item.id !== id);
        return {
          deletedItems,
        };
      },
      () => {
        if (item.is_primary) {
          this.setPrimaryById(item.id, true);
        } else {
          this.setFirstAsPrimaryIfNothingIsPrimary();
        }
      }
    );
  };

  setPrimaryById = (id, value) => {
    this.setUniqueValueById(id, "is_primary", value);
  };

  setUniqueValueById = (id, key, value, defaultOffValue = false, defaultOnValue = true) => {
    let items = [...this.props.value];
    const index = this.getIndexById(id);
    items = items.map((item, i) => {
      if (index === i) {
        item[key] = value;
      } else {
        item[key] = defaultOffValue;
      }
      return item;
    });
    if (value === defaultOffValue && items[0] !== undefined) {
      items[0][key] = defaultOnValue;
    }
    this.props.updateValue(items);
  };

  updateItem = (id, item) => {
    const items = [...this.props.value];
    const index = this.getIndexById(id);
    if (index !== -1) {
      items[index] = item;
    }
    this.props.updateValue(items);
  };

  getIndexById = (id, search = this.props.value) => {
    return findIndex(search, (item) => {
      return item.id === id;
    });
  };

  getValue = (value) => {
    const deletedItems = this.state.deletedItems.map((item) => item.id);
    return value.filter((item) => !deletedItems.includes(item.id));
  };

  addRef = (ref) => {
    if (ref && this.itemRefs.indexOf(ref) === -1) {
      this.itemRefs.push(ref);
    }
  };

  removeRef = (ref) => {
    this.itemRefs = this.itemRefs.filter((savedRef) => ref !== savedRef);
  };
}

export default InputDecorator(ListOfItems);
