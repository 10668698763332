import React, { useState } from "react";
import { useQueryClient } from "src/react-query";
import { infoToast, successToast } from "toast-service!sofe";
import { CpButton, CpDropdown, CpInput } from "canopy-styleguide!sofe";
import { createContactFilterView, patchContactFilterView } from "src/resources/contacts.resource";
import { handleError } from "src/error";
import { contactFilterViewQueries } from "src/queries";

type SaveFilterButtonProps = {
  newFiltersCount: number;
  filters: any;
  sortData: any;
  filterView: any;
  filterViewOverrides: any;
  onFilterViewCreate?: (viewId: string) => void;
};

export function SaveFiltersButton({
  newFiltersCount,
  filters,
  sortData,
  filterView,
  filterViewOverrides,
  onFilterViewCreate,
}: SaveFilterButtonProps) {
  const queryClient = useQueryClient();

  const [name, setName] = useState("");
  const [showCreateView, setShowCreateView] = useState(false);

  const trimmedName = name.trim();
  const createDisabled = !trimmedName;

  const canUpdate = !filterView.read_only && filterView.created_by !== "__DEFAULT";

  function createView() {
    createContactFilterView({
      name: trimmedName,
      filter_data: {
        visible_columns: filterViewOverrides[filterView.id]?.visibleColumns || filterView.filter_data.visible_columns,
        column_order: filterViewOverrides[filterView.id]?.columnOrder || filterView.filter_data.column_order,
        filters,
        sort_data: sortData,
      },
      sharing_data: [],
    }).subscribe((result: any) => {
      successToast(`New filter list saved: ${trimmedName}`);
      contactFilterViewQueries.invalidate();
      onFilterViewCreate?.(result.created);
    }, handleError);
  }

  function updateView() {
    patchContactFilterView(filterView.id, {
      filter_data: {
        filters,
        sort_data: sortData,
        visible_columns: filterView.filter_data.visible_columns,
        column_order: filterView.filter_data.column_order,
      },
    }).subscribe(() => {
      infoToast("Filters on the current list have been updated.");
      contactFilterViewQueries.invalidate();
    }, handleError);
  }

  function renderCreateView({ close }: any) {
    return (
      <div className="cp-p-16">
        New filter view name
        <CpInput
          placeholder="Add name"
          className="cp-mb-16"
          value={name}
          onChange={setName}
          onKeyDown={(e: any) => {
            if (!createDisabled && e.key === "Enter") {
              close();
              createView();
            }
          }}
        />
        <CpButton
          onClick={() => {
            close();
            createView();
          }}
          disabled={createDisabled}
        >
          Save
        </CpButton>
        <CpButton btnType="flat" className="cp-ml-8" onClick={close}>
          Cancel
        </CpButton>
      </div>
    );
  }

  function renderOptions({ close }: any) {
    return (
      <div className="cp-select-list">
        <button onClick={() => setShowCreateView(true)}>Save as new filter view</button>
        <button
          onClick={() => {
            close();
            updateView();
          }}
        >
          Update current filter view
        </button>
      </div>
    );
  }

  return (
    <CpDropdown
      allowContentClicks
      renderWhenClosed={false}
      contentWidth={"md"}
      onClose={() => {
        setName("");
        setShowCreateView(false);
      }}
      renderTrigger={({ toggle }: any) => (
        <CpButton btnType="flat" className="cp-ml-12" onClick={toggle}>
          Save {newFiltersCount} {newFiltersCount === 1 ? "filter" : "filters"}
        </CpButton>
      )}
      renderContent={canUpdate && !showCreateView ? renderOptions : renderCreateView}
    />
  );
}
