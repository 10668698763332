import { capitalize } from "lodash";
import { phoneNumber } from "canopy-styleguide!sofe";
import { formatSsn } from "src/common/utils.helpers";
import { TAddressValue, TAssignedClient, TContact } from "src/common/types";

export type TFieldData = {
  label: string;
  value: string;
};

export type TAddressFieldData = {
  label: string;
  value: TAddressValue;
};

export type TContactInfo =
  | {
      emails: TFieldData[];
      phones: TFieldData[];
      addresses: TAddressFieldData[];
      birthdate: TFieldData;
      tin: TFieldData;
      occupation: TFieldData;
      employer: TFieldData;
    }
  | Record<string, never>;

export type TClientPortalInfo =
  | {
      clientPortalEmail: string;
      hasClientPortalAccess: boolean;
    }
  | Record<string, never>;

type TViewContactSections = {
  contactInfo: TContactInfo;
  clientPortalInfo: TClientPortalInfo;
  assignedClients: TAssignedClient[];
};

export function mapContactDataToSections(contact?: Omit<TContact, "id">): TViewContactSections {
  if (!contact) {
    return {
      contactInfo: {},
      clientPortalInfo: {},
      assignedClients: [],
    };
  }
  const { addresses, birthdate, client_portal_email, clients, emails, employer, occupation, phones, tin } = contact;

  return {
    contactInfo: {
      emails:
        emails?.map((email) => ({
          label: `${email.is_primary ? "Primary" : capitalize(email.key)} Email`,
          value: email.value,
        })) ?? [],
      phones:
        phones?.map((phone) => ({
          label: `${phone.is_primary ? "Primary" : capitalize(phone.key)} Phone`,
          value: phone.value ? (phoneNumber(phone.value) as string) : "",
        })) ?? [],
      addresses:
        addresses?.map((address) => ({
          label: `${address.is_primary ? "Primary" : capitalize(address.key)} Address`,
          value: address.value,
        })) ?? [],
      birthdate: {
        label: "Birthday",
        value: birthdate ?? "",
      },
      tin: {
        label: "SSN",
        value: formatSsn(tin ?? ""),
      },
      occupation: {
        label: "Occupation",
        value: occupation ?? "",
      },
      employer: {
        label: "Employer",
        value: employer ?? "",
      },
    },
    clientPortalInfo: {
      clientPortalEmail: client_portal_email || "",
      hasClientPortalAccess: clients?.some((client) => !!client.latest_invite_sent) ?? false,
    },
    assignedClients: clients || [],
  };
}
