import React from "react";
import { Control, Controller } from "react-hook-form";
import { cssModules, useCssModules } from "inline-css-modules-react";
import { CpInputField, CpSelectSingle } from "canopy-styleguide!sofe";

type PhoneFieldProps = {
  control: Control;
  fieldName: string;
  watch: (field: string) => any;
  phoneTypes?: PhoneType[];
};

export type PhoneType = {
  id: string;
  name: string;
  showExtension?: boolean;
};

const defaultPhoneTypes: PhoneType[] = [
  { id: "mobile", name: "Mobile phone" },
  { id: "home", name: "Home phone" },
  { id: "work", name: "Work phone", showExtension: true },
  { id: "fax", name: "Fax phone" },
  { id: "other", name: "Other phone" },
];

export function PhoneField({ control, fieldName, watch, phoneTypes: overridePhoneTypes }: PhoneFieldProps) {
  useCssModules(css);

  const type = watch(fieldName + ".type");
  const phoneTypes = overridePhoneTypes || defaultPhoneTypes;
  const showExtension = phoneTypes.find((t) => t.id === type)?.showExtension;

  return (
    <>
      <div className={s.grid}>
        <Controller
          name={fieldName + ".type"}
          control={control}
          defaultValue={phoneTypes[0].id}
          render={({ field: { value, onChange } }) => (
            <CpSelectSingle
              triggerIsBlock
              data={phoneTypes}
              value={phoneTypes.find((type) => type.id === value)}
              onChange={(val: any) => onChange(val.id)}
            />
          )}
        />
        <div className="flex cp-gap-16">
          <div className="grow">
            <CpInputField.PhoneNumber control={control} fieldName={fieldName + ".phone"} placeholder="Phone number" />
          </div>
          {showExtension && (
            <div className={s.extField}>
              <CpInputField control={control} fieldName={fieldName + ".extension"} placeholder="Ext." maxLength={15} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const { css, s } = cssModules`
  .grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2.4rem;
  }

  .extField {
    flex-basis: 8rem;
  }
`;
