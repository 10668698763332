import React from "react";
import { CpDropdown, CpIcon } from "canopy-styleguide!sofe";
import { k, useCss } from "kremling";
import { renderContactModal } from "src/contact-modal/contact-modal.component";
import { featureEnabled } from "feature-toggles!sofe";

export function AssignedContactsCell({ value: list }) {
  const scope = useCss(css);
  const numItemsHidden = list?.length - 1;
  const viewContactModalEnabled = featureEnabled("ft_view_contact_modal");

  if (list?.length === 0) {
    return "—";
  }
  return (
    <div className="cp-flex-center cp-ellipsis" {...scope} onClick={(e) => e.stopPropagation()}>
      <div className="flex-1 cp-ellipsis">
        <div className="flex-1 cp-ellipsis">
          {viewContactModalEnabled ? (
            <a onClick={() => renderContactModal({ contactId: list[0].id })}>
              {list[0].name || `${list[0].first_name} ${list[0].last_name}` || ""}
            </a>
          ) : (
            list[0].name || `${list[0].first_name} ${list[0].last_name}` || ""
          )}
        </div>
      </div>
      {list?.length > 1 && (
        <CpDropdown
          contentWidth={304}
          allowContentClicks
          appendTo="parent"
          renderWhenClosed={false}
          renderTrigger={({ toggle }) => (
            <button className="flex-none more-button cp-body-sm" onClick={toggle}>
              {`+${numItemsHidden} more`}
            </button>
          )}
          renderContent={({ close }) => (
            <div className="dropdown-content">
              <div className="cp-wt-semibold cp-body cp-p-16">Assigned Contacts</div>
              {list.map((item, i) => {
                return (
                  <div className="cp-p-8 cp-ml-16 cp-flex cp-flex-spread-center" key={i}>
                    <div className="cp-ellipsis cp-flex-column">
                      <div className="cp-ellipsis">
                        {viewContactModalEnabled ? (
                          <a onClick={() => renderContactModal({ contactId: item.id })}>
                            {item.name || `${item.first_name} ${item.last_name}` || ""}
                          </a>
                        ) : (
                          item.name || `${item.first_name} ${item.last_name}` || ""
                        )}
                      </div>
                      <div className="cp-ellipsis cp-color-app-secondary-text cp-caption">{item.label}</div>
                    </div>
                    <CpIcon
                      name={item.user_invited_at ? "checkmark-circle-open-small" : "remove-circle-open-small"}
                      className="cp-mh-8 shrink-0"
                      fill={item.user_invited_at ? "var(--cp-color-app-success-text)" : "var(--cp-color-app-icon)"}
                    />
                  </div>
                );
              })}
            </div>
          )}
        />
      )}
    </div>
  );
}

const css = k`
.more-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 0.4rem;
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

`;
