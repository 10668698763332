import React, { useState } from "react";
import { CpButton, CpInputField, CpIcon, CpTooltip, CpModal } from "canopy-styleguide!sofe";
import { cssModules, useCssModules } from "inline-css-modules-react";
import FieldSection from "src/common/field-section.component";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ClientSelectField } from "./client-select-field.component";
import { ContactType } from "src/common/form/contact-type.component";
import { TClient } from "src/common/types";

type AssignedClientsSectionProps = {
  contactName?: string;
  contactId?: string;
};

export function AssignedClientsSection({ contactName, contactId }: AssignedClientsSectionProps) {
  useCssModules(css);
  const [warningModalObj, setWarningModalObj] = useState<{ index: number; name: string } | undefined>();
  const fieldName = "assigned_clients";
  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: fieldName,
    control,
  });

  const assignedClients = watch(fieldName);
  function filterClients(clients: TClient[]) {
    const selectedClientIds = assignedClients?.map((assignedClient: { client: TClient }) => assignedClient.client?.id);
    if (!selectedClientIds?.length) {
      return clients;
    } else {
      return clients.filter((c) => !selectedClientIds.includes(c.id));
    }
  }

  return (
    <FieldSection label="Assigned Clients">
      {fields.map((field, index) => {
        const assignedClient = assignedClients?.[index];
        if (!assignedClient) return null;
        const isPrimary = assignedClient.contact_type === "primary";
        const isSpouse = assignedClient.contact_type === "spouse";
        const hasAccess = assignedClient.client?.has_access ?? true;
        return (
          <div key={field.id} className={s.grid}>
            <ClientSelectField
              control={control}
              required
              fieldName={`${fieldName}.${index}.client`}
              label="Client"
              filterClients={filterClients}
              disabled={isPrimary || !hasAccess}
            />
            <ContactType fieldName={`${fieldName}.${index}.contact_type`} control={control} disabled />
            <CpInputField
              control={control}
              fieldName={`${fieldName}.${index}.contact_description`}
              label="Contact description"
              disabled={!hasAccess}
            />
            {isPrimary || isSpouse ? (
              <CpTooltip
                text={`${contactName} is the ${isPrimary ? "primary contact" : "spouse"} on this client record.
View the ${assignedClient.client.name || ""} client record to update the ${isPrimary ? "primary contact" : "spouse"}.`}
              >
                <CpIcon
                  name="information-circle-open-large"
                  className="cp-mt-16 cp-color-app-icon justify-self-center"
                />
              </CpTooltip>
            ) : (
              <CpButton
                type="button"
                icon="crud-trash-large"
                aria-label={`Remove client assignment`}
                className="cp-mt-16"
                onClick={() => {
                  if (assignedClient.client?.canopy_contact_id_for_third_party === contactId) {
                    setWarningModalObj({ index, name: assignedClient.client.name });
                  } else {
                    remove(index);
                  }
                }}
                disabled={!hasAccess}
              />
            )}
          </div>
        );
      })}
      <div>
        <CpButton
          btnType="secondary"
          type="button"
          onClick={(e: any) => {
            append({ contact_type: "other" }, { shouldFocus: false });
            setTimeout(() => e.target.scrollIntoView({ behavior: "smooth" }));
          }}
        >
          Assign to Client +
        </CpButton>
      </div>
      <CpModal show={warningModalObj?.index !== undefined} onClose={() => setWarningModalObj(undefined)} width={480}>
        <CpModal.Header title="Remove contact" />
        <CpModal.Body>
          <div>
            <div className="cp-mb-16">
              Removing "{contactName}" from the "{warningModalObj?.name}" client record will affect the selections that
              have been made for this clients QBO sync.
            </div>
            <div>Please review {warningModalObj?.name}'s QBO client sync preferences.</div>
          </div>
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            className="cp-mr-8"
            onClick={() => {
              remove(warningModalObj?.index);
              setWarningModalObj(undefined);
            }}
          >
            Remove
          </CpButton>
          <CpButton btnType="flat" onClick={() => setWarningModalObj(undefined)}>
            Cancel
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    </FieldSection>
  );
}

const { css, s } = cssModules`
  .grid {
    display: grid;
    gap: 2.4rem;
    flex: 1 1;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, minmax(0, 1fr)) 3.2rem;
  }
`;
