import React from "react";
import { Control, UseControllerProps, Controller } from "react-hook-form";
import { ContactSelect } from "./base/contact-select.component";

type ContactSelectFieldProps = {
  control: Control;
  controllerOptions?: Omit<UseControllerProps, "name">;
  fieldName: string;
  label?: string;
  required?: boolean;
  filterContacts?: (contacts: any[]) => any[];
};

export function ContactSelectField({
  control,
  controllerOptions,
  fieldName,
  label,
  required = false,
  filterContacts,
}: ContactSelectFieldProps) {
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => {
        return (
          <ContactSelect
            onChange={onChange}
            value={value}
            label={label}
            required={required}
            error={error?.message}
            filterContacts={filterContacts}
            triggerRef={ref}
          />
        );
      }}
      rules={required ? { required: "This field is required" } : {}}
      {...controllerOptions}
    />
  );
}
