import React, { useMemo } from "react";
import { Control, UseControllerProps } from "react-hook-form";
import { CpSelectSingleField, CpLabel } from "canopy-styleguide!sofe";
import { useClientNamesQuery } from "src/common/queries/use-client-names-query.hook";
import { useDebouncedSearch } from "src/common/use-debounced-search.hook";

type ClientSelectFieldProps = {
  control: Control;
  controllerOptions?: Omit<UseControllerProps, "name">;
  disabled?: boolean;
  fieldName: string;
  label?: string;
  required?: boolean;
  filterClients: (clients: any[]) => any[];
};

export function ClientSelectField({
  control,
  controllerOptions,
  disabled,
  fieldName,
  label,
  required = false,
  filterClients,
}: ClientSelectFieldProps) {
  const { search, debouncedSearch, updateSearch } = useDebouncedSearch();
  const { clientNames } = useClientNamesQuery({ search: debouncedSearch });

  const filteredClients = useMemo(() => {
    if (filterClients && clientNames) {
      return filterClients(clientNames) || [];
    } else {
      return clientNames || [];
    }
  }, [clientNames, filterClients]);

  return (
    <div>
      <CpLabel required={required}>{label}</CpLabel>
      <CpSelectSingleField
        triggerIsBlock
        required={required}
        searchFilter
        searchValue={search}
        searchOnChange={updateSearch}
        fieldName={fieldName}
        control={control}
        controllerOptions={controllerOptions}
        data={filteredClients}
        disabled={disabled}
      />
    </div>
  );
}
