import React, { useEffect, useState } from "react";
// project
import Modal from "src/modal/modal.component";
import CreateEditClientModalContents from "./create-edit-client-modal-contents.component";
import { getClientAndAll } from "src/client-page-old/client-page.resource";
import { getClientsUsage } from "src/resources/clients.resource";
import { useCustomFields } from "./custom-fields.hooks";
import { useIsClientLimitModel } from "cp-client-auth!sofe";
import useQboIntegrationQuery, { invalidateQboIntegration } from "src/client-list/hooks/use-qbo-integration-query.hook";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "src/react-query";
import { handleError } from "src/error";
import { useRolesQuery } from "src/queries";

type ClientLimitModelCheckProps = {
  onModalHide: () => void;
  client: any;
  isCreate?: boolean;
  clientId?: string;
  hideBusinessChooser?: boolean;
  redirectAsPrimary?: boolean;
};

type Props = {
  onModalHide: () => void;
  client: any;
  isCreate?: boolean;
  clientId?: string;
  hideBusinessChooser?: boolean;
  redirectAsPrimary?: boolean;
  isClientLimitModel?: boolean;
  clientsUsageData: any;
};

export function CreateEditClientModalWrapper(props: any) {
  // Remove contact and contactId after CRM migration
  const { contact, contactId, client, clientId, ...rest } = props;

  return (
    <QueryClientProvider client={queryClient}>
      <CreateEditClientModal client={contact || client} clientId={contactId || clientId} {...rest} />
    </QueryClientProvider>
  );
}

export default function CreateEditClientModalClientLimitModelCheck(props: ClientLimitModelCheckProps) {
  const isClientLimitModel = useIsClientLimitModel();
  const clientsUsageData = useClientsUsageResource();

  return (
    <CreateEditClientModalWrapper
      clientsUsageData={clientsUsageData}
      isClientLimitModel={isClientLimitModel}
      {...props}
    />
  );

  function useClientsUsageResource() {
    const [clientsUsageData, setClientsUsageData] = useState(null);
    useEffect(() => {
      if (isClientLimitModel) {
        const subscription = getClientsUsage().subscribe((data: any) => setClientsUsageData(data), handleError);

        return () => subscription.unsubscribe();
      }
    }, [isClientLimitModel]); // eslint-disable-line
    // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

    return clientsUsageData;
  }
}

export function CreateEditClientModal(props: Props) {
  const { redirectAsPrimary = true, isClientLimitModel, clientsUsageData, isCreate = true } = props;
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(props.client);
  const [error, setError] = useState<Error>();

  const customFields = useCustomFields();
  const { roles } = useRolesQuery();
  const { qboIntegrationInfo } = useQboIntegrationQuery();

  useEffect(() => {
    if (props.clientId !== undefined && client === undefined && error === undefined) {
      setLoading(true);
      const req$: any = getClientAndAll(props.clientId).subscribe(
        (client: any) => {
          setClient(client);
          setLoading(false);
        },
        (err: Error) => {
          setError(err);
          setLoading(false);
          handleError(err);
        }
      );
      return () => {
        req$.unsubscribe();
      };
    }
  }, [props.clientId, client, error]); //eslint-disable-line
  // lint-TODO: Missing deps, need to test to make sure adding them doesn't break anything

  if (isClientLimitModel === null || (isClientLimitModel && !clientsUsageData)) {
    return null;
  }

  return (
    <Modal onModalHide={props.onModalHide} getInitialFocus={getInitialFocus}>
      <CreateEditClientModalContents
        loading={loading}
        error={error}
        client={client}
        isCreate={props.isCreate}
        hideBusinessChooser={props.hideBusinessChooser}
        customFields={customFields}
        roles={roles}
        redirectAsPrimary={redirectAsPrimary}
        isClientLimitModel={isClientLimitModel}
        clientsUsageData={clientsUsageData}
        qboIntegrationInfo={qboIntegrationInfo}
        checkIntegrationAuth={() => invalidateQboIntegration()}
      />
    </Modal>
  );
}

function getInitialFocus(modalRef: HTMLElement) {
  return (
    modalRef.querySelector(".businessOption") ||
    modalRef.querySelector(".firstName input") ||
    modalRef.querySelector(".business_name") ||
    modalRef.querySelector("cps-button-icon") ||
    modalRef
  );
}
